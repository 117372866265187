import React from "react";

import './App.css';

import {createTheme, CssBaseline, ThemeProvider} from '@mui/material/styles';
//import {createMuiTheme, CssBaseline, ThemeProvider} from '@material-ui/core';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

//CONTENTS
import Platform from "../pages/Contents/Platform/Platform";
import Abrechnungskompass from "../pages/Contents/Abrechnungskompass/Abrechnungskompass";
import Coaching_And_Beratung from "../pages/Contents/Coaching_And_Beratung/Coaching_And_Beratung";
import Digitalisierungssupport from "../pages/Contents/Digitalisierungssupport/Digitalisierungssupport";
import Workshops from "../pages/Contents/Workshops/Workshops";
import Mediathek from "../pages/Contents/Mediathek/Mediathek";

import GOÄ_Abrechnungskompass_Content from '../pages/Contents/GOÄ_Abrechnungskompass/GOÄ_Abrechnungskompass_Content';
import GOÄ_Abrechnungskasuistiken_Content from '../pages/Contents/GOÄ_Abrechnungskasuistiken/GOÄ_Abrechnungskasuistiken_Content';
import Schwangerschaftserstuntersuchung_Calculation from '../pages/GOÄ_Abrechnungskasuistiken_Calculation/Schwangerschaftserstuntersuchung_Calculation';
//END OF CONTENTS

import Extractor from '../pages/Extractor/Extractor';
import EBM_Koeffizienten from '../pages/EBM_Koeffizienten/EBM_Koeffizienten';
import EBM_Abrechnungskompass from '../pages/EBM_Abrechnungskompass_Old/EBM_Abrechnungskompass_Main';
//import EBM_Abrechnungskompass_New from "../pages/EBM_Abrechnungskompass/EBM_Abrechnungskompass_New";
import EBM_Abrechnungskompass_Main from "../pages/EBM_Abrechnungskompass/EBM_Abrechnungskompass_Main";
import EBM_Contents from '../pages/EBM_Abrechnungskompass/contents/EBM_Contents';
import Abrechnungskompass_CSV_Page from '../pages/EBM_Abrechnungskompass/subpages/Abrechnungskompass_CSV_Page/Abrechnungskompass_CSV_Page';
import CONData from '../pages/EBM_Abrechnungskompass/subpages/CONData/CONData';

import MissingGopFeedbackPage from "../pages/EBM_Abrechnungskompass/subpages/MissingGopFeedback/MissingGopFeedbackPage";

import EBM_Abrechnungskompass_Main_Fix from "../pages/EBM_Abrechnungskompass_Fix/EBM_Abrechnungskompass_Main";

import GOÄ_Abrechnungskompass_Main from '../pages/GOÄ_Abrechnungskompass_Calculation/GOÄ_Abrechnungskompass_Calculation_Main';
import GOÄ_Abrechnungskompass_Old from '../pages/GOÄ_Abrechnungskompass_Calculation_Old/GOÄ_Abrechnungskompass_Calculation';
import GOAE_Fachgruppenvergleich_CSV_Page from "../pages/GOÄ_Abrechnungskompass_Calculation/subpages/Fachgruppenvergleich_CSV_Page/Fachgruppenvergleich_CSV_Page";
import MissingGoaeFeedbackPage from "../pages/GOÄ_Abrechnungskompass_Calculation/subpages/MissingGoaeFeedback/MissingGoaeFeedbackPage";

import Compare from '../pages/Compare/Compare';
import EBM_Kunde from '../pages/EBM_Kunde/EBM_Kunde';
import EBM_Berechnungsdaten from '../pages/EBM_Berechnungsdaten/EBM_Berechnungsdaten';
import PDFtoCSV from '../pages/PDFtoCSV/PDFtoCSV';
import PDFtoDOCX from '../pages/PDFtoDOCX/PDFtoDOCX';
import CONtoCSV from '../pages/CONtoCSV/CONtoCSV';
import Users from '../pages/Users/Users';
import Profile from '../pages/Profile/Profile';
import Articles from '../pages/Post/views/Articles';
import Article from '../pages/Post/views/Article';
import PostNew from '../pages/Post/Post_New';
import Posts from '../pages/Post/Posts';
import SubmitFilesMain from '../pages/SubmitFiles/SubmitFilesMain';
import Filemanager from '../pages/Filemanager/Filemanager';
/*import PatentsPage from '../pages/Subpages/Patents';
import DesignsPage from '../pages/Subpages/Designs';
import PlantBreedersRightsPage from '../pages/Subpages/PlantBreedersRights';
import TradeMarksPage from '../pages/Subpages/TradeMarks';*/
import PageNotFound from '../pages/PageNotFound/PageNotFound';

import Login from '../pages/Login';
import Signup from '../pages/Signup';

import { AuthProvider } from "../contexts/AuthContext";

//import { useAuth } from '../contexts/AuthContext';

import PrivateElement from '../components/PrivateRoute';
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";

//const fontFamilySettings = '"BR Firma Medium","Roboto", "Helvetica", "Arial", sans-serif';

const theme = createTheme({
  palette: {
    primaryGreen: {
      main: '#12401F',
      contrastText: '#fff',
    }
  },
  /*typography: {
    fontFamily: [
      'BR Firma Medium',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },*/
  //typography: {
  //  fontFamily: fontFamily, // Custom font family
    /*h1: {
      fontSize: '2rem', // Custom font size for h1
      fontWeight: 500,  // Custom font weight for h1
    },
    body1: {
      fontSize: '1rem', // Custom font size for body text
      fontWeight: 400,  // Custom font weight for body text
    },*/
  //},
  /*components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'BR Firma Medium';
        }
      `,
    },
  },*/
  /*typography: {
    fontFamily: fontFamilySettings,
    body1: {
      fontFamily: fontFamilySettings,
    }
  },*/
  typography: {
    fontFamily: [
      'BR Firma Medium',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)',
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    }
  },
});

/*const theme = createTheme({
  overrides:{
    MuiAppBar:{
      root:{
        transform: 'translateZ(0)'
      }
    }
  },
  props: {
    MuiIconButton:{
      disableRipple: true
    }
  }
});*/

/*const theme = createMuiTheme({

  overrides:{
    MuiAppBar:{
      root:{
        transform: 'translateZ(0)'
      }
    }
  },
  props: {
    MuiIconButton:{
      disableRipple: true
    }
  }

});*/

{/* <Route exact path="/" component={Dashboard}></Route>
              <Route path="/patents" component={PatentsPage}></Route>
              <Route path="/designs" component={DesignsPage}></Route>
              <Route path="/pbr" component={PlantBreedersRightsPage}></Route>
              <Route path="/trademarks" component={TradeMarksPage}></Route>



<PrivateRoute path="/" element={<Dashboard />}></PrivateRoute>
                      <PrivateRoute path="/extractor" element={<Extractor />}></PrivateRoute>
                      <PrivateRoute path="/compare" element={<Compare />}></PrivateRoute>
                      <PrivateRoute path="/users" element={<Users />}></PrivateRoute>
                      <PrivateRoute path="/profile" element={<Profile />}></PrivateRoute>


              */}

function App() {

  //const { currentRole } = useAuth();

  return (
    <ThemeProvider theme={theme}>
          <BrowserRouter>
              <AuthProvider>
                  <Routes>
                      {/* Private Route to be implemented soon */}
                      <Route path="/plattform" element={<PrivateElement element={<Platform />} />} />
                      <Route path="/plattform/abrechnungskompass" element={<PrivateElement element={<Abrechnungskompass />} />} />
                      <Route path="/plattform/coaching-praxisberatung" element={<PrivateElement element={<Coaching_And_Beratung />} />} />
                      <Route path="/plattform/digitalisierungssupport" element={<PrivateElement element={<Digitalisierungssupport />} />} />
                      <Route path="/plattform/workshop" element={<PrivateElement element={<Workshops />} />} />
                      <Route path="/mediathek" element={<PrivateElement element={<Mediathek />} />} />
                      <Route path="/" element={<Navigate to="/plattform" />} />
                      {/*<Route path="/extractor" element={<PrivateElement element={<Extractor />} />} />*/}
                      <Route path="/ebm-koeffizienten" element={<PrivateElement element={<EBM_Koeffizienten />} />} />
                      <Route path="/ebm-abrechnungskompass_old" element={<PrivateElement element={<EBM_Abrechnungskompass />} />} />

                      <Route path="/ebm-abrechnungskompass" element={<PrivateElement element={<EBM_Contents />} />} />
                      <Route path="/ebm-abrechnungskompass/manuelle-eingabe" element={<PrivateElement element={<EBM_Abrechnungskompass_Main />} />} />
                      <Route path="/ebm-abrechnungskompass/dateimanager" element={<PrivateElement element={<Abrechnungskompass_CSV_Page />} />} />
                      <Route path="/ebm-abrechnungskompass/eingabe-via-con-datei" element={<PrivateElement element={<CONData />} />} />

                      <Route path="/ebm-abrechnungskompass/fehlende-ebm-ziffern" element={<PrivateElement element={<MissingGopFeedbackPage />} />} />

                      <Route path="/ebm-abrechnungskompass-fix" element={<PrivateElement element={<EBM_Abrechnungskompass_Main_Fix />} />} />

                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass" element={<PrivateElement element={<GOÄ_Abrechnungskompass_Content />} />} />
                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/fachgruppenvergleich" element={<PrivateElement element={<GOÄ_Abrechnungskompass_Main />} />} />
                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/fachgruppenvergleich/dateimanager" element={<PrivateElement element={<GOAE_Fachgruppenvergleich_CSV_Page />} />} />
                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/fachgruppenvergleich/fehlende-goä-ziffern" element={<PrivateElement element={<MissingGoaeFeedbackPage />} />} />

                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/fachgruppenvergleich_old" element={<PrivateElement element={<GOÄ_Abrechnungskompass_Old />} />} />
                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/abrechnungskasuistiken" element={<PrivateElement element={<GOÄ_Abrechnungskasuistiken_Content />} />} />
                      <Route path="/gebuehrenordnung-fuer-aerzte-abrechnungskompass/abrechnungskasuistiken/schwangerschaftserstuntersuchung" element={<PrivateElement element={<Schwangerschaftserstuntersuchung_Calculation />} />} />

                      <Route path="/compare" element={<PrivateElement element={<Compare />} />} />
                      <Route path="/ebm-kunde" element={<PrivateElement element={<EBM_Kunde />} />} />
                      <Route path="/ebm-berechnungsdaten" element={<PrivateElement element={<EBM_Berechnungsdaten />} />} />
                      <Route path="/pdftocsv" element={<PrivateElement element={<PDFtoCSV />} />} />
                      <Route path="/pdftodocx" element={<PrivateElement element={<PDFtoDOCX />} />} />
                      <Route path="/contocsv" element={<PrivateElement element={<CONtoCSV />} />} />
                      <Route path="/dokumentenupload" element={<PrivateElement element={<SubmitFilesMain />} />} />
                      <Route path="/dateimanager" element={<PrivateElement element={<Filemanager />} />} />
                      <Route path="/users" element={<PrivateElement element={<Users />} />} />
                      <Route path="/profil" element={<PrivateElement element={<Profile />} />} />
                      <Route path="/articles" element={<PrivateElement element={<Articles />} />} />
                      <Route path="/podcasts" element={<PrivateElement element={<Articles post_type="2" />} />} />
                      <Route path="/videos" element={<PrivateElement element={<Articles post_type="3" />} />} />
                      <Route path="/article/:id" element={<PrivateElement element={<Article />} />} />
                      <Route path="/post-new" element={<PrivateElement element={<PostNew />} />} />
                      <Route path="/post-new/:id" element={<PrivateElement element={<PostNew />} />} />
                      <Route path="/blog/launch-von-gynsuccess" element={<PrivateElement element={<Posts />} />} />

                      {/* Private Route to be implemented soon */}
                      <Route path="/login" element={<Login />} />
                      <Route path="/signup" element={<Signup />} />
                      <Route path="/passwort-vergessen" element={<ForgotPassword />} />
                      <Route path="/passwort-zurücksetzen" element={<ResetPassword />} />
                      <Route element={<PageNotFound />} />
                  </Routes>
              </AuthProvider>
          </BrowserRouter>
    </ThemeProvider>
  );
}

{/*


                        <Route path="/" element={<Dashboard />} />
                      <Route path="/extractor" element={<Extractor />} />
                      <Route path="/compare" element={<Compare />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/articles" element={<Articles />} />
                      <Route path="/article/:id" element={<Article />} />
                      <Route path="/post-new" element={<PostNew />} />
                      <Route path="/post-new/:id" element={<PostNew />} />



                      <Route path="/" element={<PrivateElement element={<Dashboard />} />} />
                      <Route path="/extractor" element={<PrivateElement element={<Extractor />} />} />
                      <Route path="/compare" element={<PrivateElement element={<Compare />} />} />
                      <Route path="/users" element={<PrivateElement element={<Users />} />} />
                      <Route path="/profile" element={<PrivateElement element={<Profile />} />} />
                      <Route path="/articles" element={<PrivateElement element={<Articles />} />} />


                      <Route path="/" element={<PrivateElement element={<Dashboard />} />
                      <Route path="/extractor" element={<PrivateElement element={<Extractor />} />
                      <Route path="/compare" element={<PrivateElement element={<Compare />} />
                      <Route path="/users" element={<PrivateElement element={<Users />} />} />
                      <Route path="/profile" element={<PrivateElement element={<Profile />} />} />
                      <Route path="/articles" element={<PrivateElement element={<Articles />} />} />
                      <Route path="/article/:id" element={<PrivateElement element={<Article />} />} />
                      <Route path="/post-new" element={<PrivateElement element={<PostNew />} />} />
                      <Route path="/post-new/:id" element={<PrivateElement element={<PostNew />} />} />




function App1() {
  return (
      <ThemeProvider theme={theme}>

        <BrowserRouter>
          <AuthProvider>
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard}></PrivateRoute>
              { /*<PrivateRoute path="/patents" component={PatentsPage}></PrivateRoute>
              <PrivateRoute path="/designs" component={DesignsPage}></PrivateRoute>
              <PrivateRoute path="/pbr" component={PlantBreedersRightsPage}></PrivateRoute>
              <PrivateRoute path="/trademarks" component={TradeMarksPage}></PrivateRoute> */}
{/*              <Route path="/login" component={Login}></Route>
              <Route path="/signup" component={Signup}></Route>
              <Route component={PageNotFound} />
            </Switch>
          </AuthProvider>
        </BrowserRouter>

        <CssBaseline />
      </ThemeProvider>
  );
}*/}

export default App;