import React, { useState, useEffect } from 'react';
import * as DatabaseService from '../../../services/DatabaseService';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, IconButton, CircularProgress, Alert, ThemeProvider, createTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const MissingGoaeFeedBack = () => {

    const [listJSONData, setJSONData] = useState([]);
    
    const [rows, setRows] = useState([{ id: 1, goae_ziffer: '', anzahl: '' }]);
    
    const [totalRowsCount, setTotalRowsCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [search, setSearch] = useState("");

    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const getGoaeMissingData = async (page, rowsPerPage, search, per_user_data) => {
        try {
            const JSONData = await DatabaseService.getGoaeMissingData((page + 1), rowsPerPage, search, per_user_data);
            if (JSONData && JSONData.rows) {
                setJSONData(JSONData.rows);
            }
            if (JSONData && JSONData.totalCount) {
                setTotalRowsCount(JSONData.totalCount);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    useEffect(() => {
        //setDialogInformation({ information: "Loading...", status: "loading" });
        //setLoading(true);
        getGoaeMissingData(page, rowsPerPage, search, true)
            //.then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching data:', error);
                //setLoading(false);
            });
    }, [page, rowsPerPage, search]);

    useEffect(() => {
        if (listJSONData.length > 0 && listJSONData[0].goae_missing_data_json) {
            const updatedRows = listJSONData[0].goae_missing_data_json.map(item => ({
                id: item.id,
                goae_ziffer: item.goae_ziffer,
                anzahl: item.anzahl
            }));
            setRows(updatedRows);
        }
    }, [listJSONData]);

    const handleAddRow = () => {
        const newRow = { id: rows.length + 1, goae_ziffer: '', anzahl: '' };
        setRows([...rows, newRow]);
        setIsSaveDisabled(false);
        setShowAlert(false);
    };

    const handleDeleteRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
        setIsSaveDisabled(false);
        setShowAlert(false);
    };

    const handleInputChange = (id, field, value) => {
        const updatedRows = rows.map(row => 
            row.id === id ? { ...row, [field]: value } : row
        );
        setRows(updatedRows);
        setIsSaveDisabled(false);
        setShowAlert(false);
    };

    const handleInputClick = () => {
        setIsSaveDisabled(false);
        setShowAlert(false);
    };

    const handleSave = () => {
        setIsSaving(true);

        const filteredRows = rows
        .filter(row => row.goae_ziffer !== '');

        /*console.log(rows);
        console.log(JSON.stringify(rows));*/

        insertGoaeMissingData(JSON.stringify(filteredRows));
    };

    const insertGoaeMissingData = async (data) => {
        try {
            const response = await DatabaseService.insertGoaeMissing(data);
            if (response.success) {
                setIsSaving(false);
                setIsSaveDisabled(true);
                setShowAlert(true);
            } else {
                console.error('Failed to insert data', response.message);
            }
        } catch (error) {
            console.error('Error inserting data:', error);
        }
    };

    return (
        <>
            <TableContainer component={Paper} className="table-container">
                <Table size="small" aria-label="simple table" className="table">
                    <TableHead>
                        <TableRow className="table-body-row">
                            <TableCell colSpan={4} className="table-body-cell-header-description">
                                GOÄ-Ziffern, die Sie noch nicht eintragen konnten
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id} className="last-child-cell">
                                <TableCell width={"33%"} className="table-body-cell">
                                    <TextField
                                        type="text"
                                        placeholder="GOÄ-Ziffer"
                                        size="small"
                                        variant="filled"
                                        hiddenLabel
                                        className="text-field"
                                        value={row.goae_ziffer}
                                        onChange={(e) => handleInputChange(row.id, 'goae_ziffer', e.target.value)}
                                        onClick={handleInputClick}
                                    />
                                </TableCell>
                                <TableCell width={"33%"} className="table-body-cell">
                                    <TextField
                                        type="text"
                                        placeholder="0"
                                        size="small"
                                        variant="filled"
                                        hiddenLabel
                                        className="text-field"
                                        value={row.anzahl}
                                        onChange={(e) => handleInputChange(row.id, 'anzahl', e.target.value)}
                                        onClick={handleInputClick}
                                    />
                                </TableCell>
                                <TableCell width={"17%"} className="table-body-cell">
                                    <IconButton variant="contained" onClick={() => handleDeleteRow(row.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow className="last-child-cell">
                            <TableCell colSpan={4} className="table-body-cell">
                                <Button variant="contained" color="primary" onClick={handleAddRow} style={{ marginRight: '10px' }}>
                                    Hinzufügen
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaveDisabled || isSaving}>
                                    {isSaving ? <CircularProgress size={24} /> : "Speichern"}
                                </Button>
                            </TableCell>
                        </TableRow>
                        {showAlert &&
                        <TableRow className="last-child-cell">
                            <TableCell colSpan={4} >
                                 <Alert severity="success">Die Daten wurden erfolgreich gespeichert!</Alert>
                            </TableCell>
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            
        </>
    );
};

export default MissingGoaeFeedBack;