import { apiCall } from '../../apiHelpers/apiCall';

export const delGoaeMissingData = async (data) => {

    try {

        const response = await apiCall("/api/del/goae_missing", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: data })
        });

        return response;

    } catch (error) {
        console.log("gop_missing_remove");
        console.error(error.message);
    }
} 