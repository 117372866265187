import { apiCall } from '../../apiHelpers/apiCall';

export const delAbrechnungskompassCSVData = async (data) => {

    try {

        const response = await apiCall("/api/del/abrechnungskompass_csv", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: data })
        });

        return response;

    } catch (error) {
        console.log("abrechnungskompass_csv_data_remove");
        console.error(error.message);
    }
} 