import { apiCall } from '../../apiHelpers/apiCall';

export const insertCONData = async (con_data_json, scheine_count, offene_sprechstunde) => {
    try {
        
        if (con_data_json === "") {
            throw new Error("con_data_json cannot be empty");
        }
        
        const response = await apiCall("/api/insert_con_data", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ con_data_json: con_data_json, scheine_count: scheine_count, offene_sprechstunde: offene_sprechstunde })
        });

        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            throw new Error('Failed to insert data');
        }
    } catch (error) {
        console.error('Error in insertCONData', error.message);
        throw error;
    }
};