import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';

const ViewFormAbrechnungPopup = ({ abrechnungBillingOption, abrechnungBillingOptionList = [{id:0,billing_option:''}], handleWeiterAbrechnungBillingOptionClick, error }) => {
    
    // Manage selected option locally
    const [selectedOption, setSelectedOption] = useState(abrechnungBillingOption);

    const handleOptionChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedOption(value);
        } else {
            setSelectedOption('');
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        // Optional: Handle form submission if needed
    };

    return (
        <Box 
            sx={{ padding: 4, textAlign: 'center' }} 
            component="form" 
            onSubmit={handleFormSubmit}
        >
            <Typography variant="h6">
                Ist diese Abrechnung eine Zwischenabrechnung oder eine Quartalsabrechnung?
            </Typography>
            <br />
            {abrechnungBillingOptionList.map(option => (
                <FormControlLabel
                    key={option.id}
                    control={
                        <Checkbox
                            checked={selectedOption === option.id}
                            onChange={handleOptionChange}
                            value={option.id} // Ensure this matches the type of selectedAbrechnungBillingOption
                        />
                    }
                    label={option.billing_option}
                />
            ))}
            {error && (
                <>
                    <br />
                    <Typography variant="body2" color="error">
                        Bitte wählen Sie eine Option aus, um fortzufahren.
                    </Typography>
                </>
            )}
            <br /><br />
            <Button 
                variant="contained" 
                color="primary" 
                onClick={() => handleWeiterAbrechnungBillingOptionClick(selectedOption)}
                type="button" // Ensures the button does not submit the form
            >
                Weiter
            </Button>
        </Box>
    )
}

export default ViewFormAbrechnungPopup;