import React, { useState, useEffect } from 'react';
import * as DatabaseService from '../services/DatabaseService';

export const useAbrechnungBillingOption = () => {
 
  const [abrechnungBillingOptionList, setAbrechnungBillingOptionList] = useState([]);

  const getAbrechnungBillingOptionData = async () => {
    try {
      const JSONData = await DatabaseService.getAbrechnungBillingOptionData();

      if (JSONData && JSONData.rows) {
        setAbrechnungBillingOptionList(JSONData.rows);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getAbrechnungBillingOptionData();
  }, []);

  return { abrechnungBillingOptionList };
};