import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, Button, Card, CardContent, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import MuiDrawer from '../../components/MuiDrawer';
import teamGS from '../../components/assets/images/template/bild3_teamGS_adjusted.webp';
import Gyntreff from '../../components/assets/images/template/Bild1_14_Gyntreff.webp';
import Bild2 from '../../components/assets/images/template/Bild2_Gynsuccess.webp';
import Bild4 from '../../components/assets/images/template/Bild4_sz.webp';
import Bild5 from '../../components/assets/images/template/Bild5_gh.webp';


const BlogContainer = styled(Box)({
  maxWidth: '1200px',
  margin: 'auto',
  padding: '20px',
  fontFamily: 'BR Firma Light',
});

const BlogImage = styled('img')({
  width: '50%',
  height: 'auto',
  borderRadius: '10px',
  marginBottom: '20px',
  marginLeft:'auto',
  marginRight:'auto',
  display:'block',
  [`@media (max-width: 600px)`]: {
    width: '100%!important',
  },
});

const BlogContent = styled(Box)({
  fontSize: '18px',
  color: '#333333',
  fontFamily: 'BR Firma Light',
});


const BlogPost = () => {
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState(null);

  useEffect(() => {
    // Simulate a database fetch
    setTimeout(() => {
      setPost({
        image: teamGS,
        title: 'Launch der GynSuccess Plattform auf dem Kongress „AMBULANZ TRIFFT KLINIK“',
    //  content: [
    //       'Mit großer Freude haben wir den offiziellen Start von GynSuccess im Rahmen des Kongresses Gynäkologentreff – 14. Wissenschaftlichen Tagung vom 05. bis 07. September 2024 gefeiert. Auf Einladung der Kongressorganisatorin, Frau Dr.med. Susanne Hampel, Frauenärztin aus Berlin und Mitbegründerin des Kongresses, hatten wir die einmalige Gelegenheit, unser Unternehmen und unsere Vision vor einem engagierten Fachpublikum zu präsentieren. Seit diesem Tag sind wir offiziell online!',
    //     ],
        secondSection: {
            image: Gyntreff,
            content: ['Mit großer Freude haben wir den offiziellen Start von GynSuccess im Rahmen des Kongresses Gynäkologentreff – 14. Wissenschaftlichen Tagung vom 05. bis 07. September 2024 gefeiert. Auf Einladung der Kongressorganisatorin, Frau Dr.med. Susanne Hampel, Frauenärztin aus Berlin und Mitbegründerin des Kongresses, hatten wir die einmalige Gelegenheit, unser Unternehmen und unsere Vision vor einem engagierten Fachpublikum zu präsentieren. Seit diesem Tag sind wir offiziell online!',
          ],},
          thirdSection: {
            image: Bild2,
            title:'Unternehmens\u00ADpräsentation: Ein Blick in die Zukunft der Niederlassung',
            content: [
          'Am 06. September 2024 durften wir unser Unternehmen mit dem Thema „Gibt es noch mutige junge Kolleginnen und Kollegen, welche in die eigene Niederlassung einer Praxis gehen, und wie können diese unterstützt werden? Ein Blick in die Zukunft.“ präsentieren. Diese gab uns die Möglichkeit, unsere Vision und Mission dem Publikum vorzustellen. Wir sind überzeugt, dass junge Gynäkologinnen und Gynäkologen unterstützt und gefördert werden müssen, um den Schritt in die Selbstständigkeit wagen zu können. In unserem Beitrag haben wir gezeigt, wie wichtig es ist, nicht nur Mut zu haben, sondern auch die richtigen Werkzeuge und Netzwerke, um langfristig erfolgreich zu sein. Unser Ziel ist es, angehenden Praxisinhaberinnen und Praxisinhabern mit umfassenden Konzepten und einem starken Netzwerk zur Seite zu stehen.',
        ],},
        fourthSection: {
            image: Bild4,
            title:'Workshop: Wege in die Selbstständigkeit',
            content: [
          'Bereits einen Tag zuvor, am 05. September 2024, konnten wir im Rahmen eines interaktiven Workshops unter dem Titel „ENDLICH EIGENE CHEFIN, EIGENER CHEF – MEINE FRAUENARZTPRAXIS, MEINE ZUKUNFT IN DER SELBSTSTÄNDIGKEIT!“ die Kerninhalte von GynSuccess in die Diskussion einbringen. Ein Bild, das Kleidung, Person, Im Haus, Mobiliar enthält.',
          'Der Workshop bot den Teilnehmer-innen und Teilnehmern einen praxis-nahen Einblick in die Herausforderun-gen und Chancen der Selbst-ständigkeit in der gynäkologischen Praxis. Es wurde deutlich, dass viele junge Kolleginnen und Kollegen vor ähnlichen Hürden stehen, sei es beim Praxiskauf, der Gründung oder der strategischen Weiterentwicklung einer Praxis.',
          'GynSuccess widmet sich genau diesen Herausforderungen und bietet maßgeschneiderte Unterstützung, von der Praxisgründung bis hin zur gewinnoptimierten Praxisführung. Unsere Tools zur Abrechnungsoptimierung sowie unsere innovativen Konzepte zur Organisations- und Personalentwicklung wurden im Workshop vorgestellt und mit den Gynäkologinnen und Gynäkologen intensiv diskutiert.',
        ],},
        fifthSection: {
            image: Bild5,
            title:'Austausch mit den Gründern von GynSuccess',
            content: [
          'Im Mittelpunkt des Workshops stand der Dialog mit den Firmengründern Gerhard Hofbauer und Dr. med. Sebastian Zuber. „Gemeinsam diskutierten wir mit den Teilnehmerinnen und Teilnehmern, wie innovative und intuitive Konzepte nicht nur die Effizienz einer gynäkologischen Praxis steigern können, sondern auch den Weg zur erfolgreichen Selbstständigkeit ebnen. Die Resonanz auf unsere Ideen und Ansätze war durchweg positiv. Besonders unser Konzept einer geschlossenen, werbefreien Community für neuniedergelassene Gynä-kologinnen und Gynäkologen, in dem Wissen, dass Erfahrungen und Tools für eine erfolgreiche Praxisführung ausgetauscht werden können, fand großen Anklang.',
        ],},
        sixthSection: {
            title:'Die Bedeutung des Kongresses „AMBULANZ TRIFFT KLINIK“',
            content: [
          'Der Kongress „AMBULANZ TRIFFT KLINIK„, organisiert von der ATK »Ambulanz trifft Klinik« GbR unter der Leitung von Frau Dr. med. Susanne Hampel, hat sich als bedeutende Plattform für den kollegialen Austausch zwischen Klinik und Praxis etabliert. Seit seiner Gründung im Jahr 2010 setzt der Kongress neue Maßstäbe in der ärztlichen Weiterbildung und stärkt die Zusammenarbeit zwischen ambulanter und stationärer Versorgung. „Der Erfolg des diesjährigen Kongresses und die hohe Beteiligung an unseren Präsentationen und Workshops bestärken uns in unserer Mission, Gynäkologinnen und Gynäkologen auf ihrem Weg zur erfolgreichen Praxisführung zu begleiten. Wir freuen uns darauf, GynSuccess als verlässlichen Partner für junge Kolleginnen und Kollegen in der Selbstständigkeit zu etablieren.“, so Dr.med. Sebastian Zuber',
        ],},
        seventhSection: {

            title:'Was kommt als Nächstes?',
            content: [
          'Nach diesem erfolgreichen Launch werden wir in den kommenden Monaten unsere Plattform weiter ausbauen und zusätzliche innovative Services anbieten, die bei der Praxisgründung und -führung unterstützen. Bleiben Sie gespannt auf weitere spannende Entwicklungen und kommende Veranstaltungen, bei denen wir uns für Sie engagieren werden!',
        ],},
      });
      setLoading(false);
    }, 2000); // Simulated loading delay
  }, []);

  return (
    <MuiDrawer>
      <BlogContainer>
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height={400} />
        ) : (
          <>
            {/* Main Blog Section */}
            <BlogImage src={post.image} alt={post.title} />
            <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#75BB44' }}>
              {post.title}
            </Typography>
            {/* <BlogContent>
              {post.content.map((paragraph, index) => (
                <Typography key={index} paragraph sx={{ lineHeight: '1.8em', fontSize: '18px' }}>
                  {paragraph}
                </Typography>
              ))}
            </BlogContent> */}

            {/* Second Section */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
                marginTop: 4,
                marginBottom :3,
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* Text on the left */}
              <Box sx={{ width: '100%' , maxWidth: '600px', '@media (max-width: 600px)': { maxWidth: '100%!important', // Adjust width for mobile
               }, }}>
              <BlogContent>
              {post.secondSection.content.map((paragraph, index) => (
                <Typography key={index} paragraph sx={{ lineHeight: '1.8em', fontSize: '18px' }}>
                  {paragraph}
                </Typography>
              ))}
            </BlogContent>
              </Box>

              {/* Image floated right */}
              <Box sx={{ flex: 1 }}>
                <Box
                component ="img"
                  src={post.secondSection.image}
                  alt="GYNTreff"
                 sx={{ width: '100%', maxWidth:'250px', borderRadius: '8px', '@media (max-width: 600px)': { maxWidth: '100%!important', // Adjust width for mobile
               }, }}
                />
              </Box>
            </Box>


      {/* Third Section */}
            <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#75BB44' }}>
              {post.thirdSection.title}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                gap: 2,
                marginTop: 4,
                marginBottom: 3,
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* Text and Image on the right */}
              <Box sx={{ flexShrink: 0 }}>
                <Box
                component = "img"
                  src={post.thirdSection.image}
                  alt="Third Image"
                  sx={{ width: '100%', borderRadius: '8px',maxWidth: '600px', '@media (max-width: 600px)': { maxWidth: '100%!important', // Adjust width for mobile
               }, }}
                />
              </Box>
              <Box  sx={{ flex: 1, textAlign: 'left' }}>
                <Typography  sx={{ fontSize: '18px', lineHeight: '1.8em' }}>
                  {post.thirdSection.content}
                </Typography>
              </Box>
            </Box>
                  {/* Fourth Section */}
                  <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#75BB44' }}>
              {post.fourthSection.title}
            </Typography>
                  <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                gap: 2,
                marginTop: 4,
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* Text and Image on the right */}
              <Box sx={{ flex: 1, textAlign: 'left' }}>
              {post.fourthSection.content.slice(0, -1).map((paragraph, index) => (
                <Typography key={index} sx={{ fontSize: '18px', lineHeight: '1.8em' }} paragraph>
                    {paragraph}
                </Typography>
                ))}
            </Box>
            <Box sx={{ flexShrink: 0 }}>
                <Box
                component = "img"
                  src={post.fourthSection.image}
                  alt="Fourth Image"
                  sx={{ width: '100%', maxWidth:'600px', borderRadius: '8px', '@media (max-width: 600px)': { maxWidth: '100%!important', // Adjust width for mobile
               }, }}
                />
              </Box>
            </Box>
            {/* Last Paragraph outside the Box */}
            <Typography sx={{ fontSize: '18px', lineHeight: '1.8em', marginTop: 2, marginBottom: 3 }}>
            {post.fourthSection.content[post.fourthSection.content.length - 1]}
            </Typography>


           {/* Fifth Section */}
           <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#75BB44' }}>
           {post.fifthSection.title}
         </Typography>

         <Box
           sx={{
             display: 'flex',
             alignItems: 'flex-start',
             justifyContent: 'flex-end',
             gap: 2,
             marginTop: 4,
             marginBottom: 3,
             flexDirection: { xs: 'column', sm: 'row' },
           }}
         >
           {/* Text and Image on the right */}
           <Box sx={{ flexShrink: 0  }}>
             <Box
               component ="img"
               src={post.fifthSection.image}
               alt="Fifth Image"
                sx={{ maxWidth: '275px', borderRadius: '8px', width:'100%', '@media (max-width: 600px)': { maxWidth: '100%!important', // Adjust width for mobile
               },  }}
             />
           </Box>
           <Box  sx={{ flex: 1, textAlign: 'left' }}>
             <Typography  sx={{ fontSize: '18px', lineHeight: '1.8em' }}>
               {post.fifthSection.content}
             </Typography>
           </Box>
         </Box>
           {/* Sixth Section */}
           <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#75BB44' }}>
           {post.sixthSection.title}
         </Typography>

         <Box
           sx={{
             display: 'flex',
             alignItems: 'flex-start',
             justifyContent: 'flex-end',
             gap: 2,
             marginTop: 2,
             marginBottom: 3,
             flexDirection: { xs: 'column', sm: 'row' },
           }}
         >
           <Box  sx={{ flex: 1, textAlign: 'left' }}>
             <Typography  sx={{ fontSize: '18px', lineHeight: '1.8em' }}>
               {post.sixthSection.content}
             </Typography>
           </Box>
         </Box>
           {/* Seventh Section */}
           <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#75BB44' }}>
           {post.seventhSection.title}
         </Typography>
         <Box
           sx={{
             display: 'flex',
             alignItems: 'flex-start',
             justifyContent: 'flex-end',
             gap: 2,
             marginTop: 2,
             marginBottom: 3,
             flexDirection: { xs: 'column', sm: 'row' },
           }}
         >
           <Box  sx={{ flex: 1, textAlign: 'left' }}>
             <Typography  sx={{ fontSize: '18px', lineHeight: '1.8em' }}>
               {post.seventhSection.content}
             </Typography>
           </Box>
         </Box>
         </>
        )}
      </BlogContainer>
    </MuiDrawer>
  );
};

export default BlogPost;
