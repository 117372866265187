export const getPLZ_OrtData = async () => {
   
    try{
        const response = await fetch(`/api/get_plz_ort_data`);
        
        const jsonData = await response.json();

        return jsonData;

    }catch(error){
        console.error(error.message);
    }
}