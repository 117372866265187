import { apiCall } from '../../apiHelpers/apiCall';

export const getCONData = async (page, rowsPerPage, search) => {
   
    try{
        const response = await apiCall(`/api/get_con_data?page=${page}&perPage=${rowsPerPage}&s=${search}`);
        
        const jsonData = await response.json();

        return jsonData;

    }catch(error){
        console.error(error.message);
    }
}