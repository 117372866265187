export const getAbrechnungBillingOptionData = async () => {
   
    try{
        const response = await fetch(`/api/get_abrechnung_billing_list`);
        
        const jsonData = await response.json();

        return jsonData;

    }catch(error){
        console.error(error.message);
    }
}