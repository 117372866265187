import { apiCall } from '../../apiHelpers/apiCall';

export const insertGoaeMissing = async (goae_missing_data_json) => {
    try {
        
        if (goae_missing_data_json === "") {
            throw new Error("goae_missing_data_json cannot be empty");
        }
        
        const response = await apiCall("/api/insert_goae_missing", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ goae_missing_data_json: goae_missing_data_json })
        });

        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            throw new Error('Failed to insert data');
        }
    } catch (error) {
        console.error('Error in insert goae missing', error.message);
        throw error;
    }
};