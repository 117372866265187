import { apiCall } from '../apiHelpers/apiCall';

export const getGOÄ_Abrechnungskompass = async () => {
   
    try{
        const response = await apiCall(`/api/goa_abrechnungskompass`);
        
        const jsonData = await response.json();

        return jsonData;

    }catch(error){
        console.error(error.message);
    }
}