import React, { useState, useEffect, useMemo } from 'react';
import * as DatabaseService from '../../../services/DatabaseService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { checkDeviationPercentColor } from '../calculations/ebmDeviationConditions';
import countGesamtpotenzialNoTrap from '../calculations/countGesamtpotenzialNoTrap';
import countIhrPotencialNoTrap from '../calculations/countIhrPotencialNoTrap';
import countIhrPotencialIncluded from '../calculations/countIhrPotencialIncluded';
import countGesamtpotenzialIncluded from '../calculations/countGesamtpotenzialIncluded';
import TableSection from './components/Table_Section';
import Charts_Section from './components/Charts_Section';
import { LoadingSkeletonLayout } from '../components/Loading/LoadingSkeletonLayout';
import { useEnvData } from '../../../services/envService';
import DisplayInfoStyle from '../styles/DisplayInfoStyle';
import { userTitleList, userSalutationList } from '../../../constants/userOptionsList';
import readableDateFormat from '../../../utils/readableDateFormat';
import germanFormatCurrency from '../../../utils/germanFormatCurrency';

const EBM_Abrechnungskompass_Results = (props) => {

    const { scheine=0, kv=0, kvName='', gopNumbersInput={}, viewOnlyMode, userStatus, debug=false, userInfo={}, abrechnungBillingOption, offeneSprechstunde, created_at } = props;

    const { isDevMode } = useEnvData();
    const [rowsFromDB, setRowsFromDB] = useState([]);
    const [rowsResult, setRowsResult] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [loadingCapture, setLoadingCapture] = useState(false); // Add loading state

    const [tableMaxHeight, setTableMaxHeight] = useState(500);

    const getEBMRowsData = async (scheine, kv) => {
        try {
          const JSONData = await DatabaseService.getEBMAbrechnungskompassSearchByStates(scheine, kv);
          if (JSONData && JSONData.rows) {
            setRowsFromDB(JSONData.rows);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } 
    };

    useEffect(() => {
        getEBMRowsData(scheine, kv); // Fetch initial data
    }, [kv]);

    const captureScreenshot = () => {
        setLoadingCapture(true); // Set loading to true when capturing screenshot
        setTableMaxHeight('auto'); // Set table height to auto for full height capture
        requestAnimationFrame(() => { // Wait for the DOM to update
            const element = document.getElementById('capture'); // Adjust the ID to the element you want to capture
            if (element) {
                captureElementToPDF(element);
            } else {
                console.error('Element with ID "capture" not found.');
                setLoadingCapture(false); // Reset loading state if element is not found
            }
        }, 1000); // Adjust the timeout duration as needed
    };

    const captureElementToPDF = (element) => {
        html2canvas(element, {
            backgroundColor: null, // Remove background color to avoid gray bars or shadows
            scale: 2, // Increase the scale for better quality
            useCORS: true // Enable cross-origin resource sharing if needed
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth() - 20; // Add margin
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            const pageHeight = pdf.internal.pageSize.getHeight() - 20; // Add margin
            let heightLeft = pdfHeight;
            let position = 10; // Start position with margin

            pdf.addImage(imgData, 'PNG', 10, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight;

            while (heightLeft > 0) {
                position = heightLeft - pdfHeight + 10; // Adjust position for margin
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 10, position, pdfWidth, pdfHeight);
                heightLeft -= pageHeight;
            }

            const today = new Date();
            const date = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
            const filename = `EBM Abrechnungskompass ${date}.pdf`;

            pdf.save(filename);
            setTableMaxHeight(500); // Reset table height back to 500px after capture
            setLoadingCapture(false); 
        });
    };

    useEffect(() => {
        if (rowsResult.length > 0) {
            setLoading(false); // Set loading to false when data is fetched
        }
    }, [rowsResult]);

    const memoizedRowsResult = useMemo(() => {
        if (rowsFromDB.length > 0) {
            let anzahl_input_01770 = 0;
            return rowsFromDB.map(row => {
                let euro_potencial = 0;
                let euro_potencial_mutterschaftsvorsorge_ziffern = 0;
                let ideal_number_of_treatments = 0;
                let anzahl = gopNumbersInput[`fallzahl_${row.gop}`] || 0; 

                if(row.gop === '01770' && parseInt(anzahl) > 0){
                    anzahl_input_01770 = parseInt(anzahl);
                }

                if(anzahl_input_01770 > 0){ //Mutterschaftsvorsorge-Ziffern formula
                    ideal_number_of_treatments = (parseInt(row.exclude_scheine) === 1) 
                        ? (anzahl_input_01770 * row.ebm_koeffizient) 
                        : row.ideal_number_of_treatments;
                    euro_potencial_mutterschaftsvorsorge_ziffern = ideal_number_of_treatments * row.euro_value_of_a_treatment;
                } else {
                    ideal_number_of_treatments = (parseInt(row.exclude_scheine) === 1) 
                        ? (anzahl * row.ebm_koeffizient) 
                        : row.ideal_number_of_treatments;
                }

                let deviation = anzahl - ideal_number_of_treatments;
                let deviation_in_percent = (deviation / ideal_number_of_treatments) * 100;
                euro_potencial = Math.abs(parseFloat(deviation)) * parseFloat(row.euro_value_of_a_treatment);
                let euro_potencial_show = 1;

                if(parseInt(anzahl) <= parseInt(ideal_number_of_treatments)){
                    euro_potencial_show = 1;
                } else {
                    euro_potencial_show = 0;
                }

                let deviation_in_percent_status = checkDeviationPercentColor(deviation_in_percent, row.gop);

                row.anzahl = anzahl;
                row.deviation = deviation;
                row.deviation_in_percent = deviation_in_percent;
                row.euro_potencial_show = euro_potencial_show;
                row.adjusted_euro_potencial = euro_potencial;
                row.ideal_number_of_treatments = ideal_number_of_treatments;
                row.euro_potencial_mutterschaftsvorsorge_ziffern = euro_potencial_mutterschaftsvorsorge_ziffern;
                row.deviation_in_percent_status = deviation_in_percent_status;
                return { ...row };
            });
        }
        return [];
    }, [rowsFromDB, scheine, gopNumbersInput]);

    useEffect(() => {
        if (rowsFromDB.length > 0) {
            setRowsResult(memoizedRowsResult);
        }
    }, [memoizedRowsResult]);

    const gesamtpotenzial_notrap = countGesamtpotenzialNoTrap(rowsResult, false);
    const ihrpotenzial_notrap = countIhrPotencialNoTrap(rowsResult, false);
    const ihrPotencialIncluded = countIhrPotencialIncluded(rowsResult, false, gopNumbersInput);
    const gesamtpotenzial_included = countGesamtpotenzialIncluded(rowsResult, false, gopNumbersInput);
    const ihrPotencialIncluded_excludedGOP = countIhrPotencialIncluded(rowsResult, false, gopNumbersInput, true);
    const gesamtpotenzial_included_excludedGOP = countGesamtpotenzialIncluded(rowsResult, false, gopNumbersInput, true);

    /*console.log('rowsResult: ', rowsResult);
    console.log('Total gesamtpotenzial_notrap: ', gesamtpotenzial_notrap);
    console.log('Total ihrpotenzial_notrap: ', ihrpotenzial_notrap);
    console.log('Total ihrPotencialIncluded: ', ihrPotencialIncluded);
    console.log('Total gesamtpotenzial_included: ', gesamtpotenzial_included);*/

    const displayInfo = (userInfo, scheine, kvName, created_at = "") => {
        const showColon = kvName || created_at ? ':' : '';
        const scheineInput = scheine ? `${germanFormatCurrency(parseInt(scheine), false, false)} Scheine` : '';
        const showComma1 = (scheineInput && kvName) ? ',' : '';
        const kv_Name = kvName ? `KV ${kvName}` : '';
        const userSalutation = userSalutationList.find(salutation => salutation.id === userInfo.salutation && salutation.id !== '')?.title || '';
        const userTitle = userTitleList.find(title => title.id === userInfo.user_title && title.id !== '')?.title || '';
        const formattedDate = created_at ? 'Erstellungsdatum: ' + readableDateFormat(created_at) : '';
        const showComma2 = (kvName && created_at) ? ',' : '';

        return (
            <DisplayInfoStyle>
                {`EBM-Abrechnungsergebnis für 
                    ${userSalutation} 
                    ${userTitle}
                    ${userInfo.fname}
                    ${userInfo.lname}${showColon} 
                    ${scheineInput}${showComma1}
                    ${kv_Name}${showComma2} 
                    ${formattedDate}`}
            </DisplayInfoStyle>
        );
    };

    return (
        <>  
            {loading ? <LoadingSkeletonLayout /> : <>
                    
                    {
                        (parseInt(userStatus) === 1) && <>

                            <Box sx={{textAlign:'right'}}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={captureScreenshot} 
                                    disabled={loadingCapture}
                                    startIcon={loadingCapture && <CircularProgress size={20} />}
                                >
                                    {loadingCapture ? 'Einfangend...' : 'PDF herunterladen'}
                                </Button>
                            </Box>
                            <br />
                        </>
                    }
                    
                    <div id="capture" style={{ marginTop: parseInt(userStatus) === 1 ? '-55px' : '0' }}>
                        {
                            displayInfo(userInfo, scheine, kvName, created_at)
                        }
                        <br />
                        <Charts_Section 
                            rowsResult={rowsResult}
                            gesamtpotenzial_included={gesamtpotenzial_included} 
                            ihrPotencialIncluded={ihrPotencialIncluded}
                            gesamtpotenzial_included_excludedGOP={gesamtpotenzial_included_excludedGOP}
                            ihrPotencialIncluded_excludedGOP={ihrPotencialIncluded_excludedGOP}
                            gesamtpotenzial_notrap={gesamtpotenzial_notrap}
                            ihrpotenzial_notrap={ihrpotenzial_notrap}
                            scheine={scheine}
                            offeneSprechstunde={offeneSprechstunde}
                        />
                        
                        <br />
                        
                        <TableSection rowsResult={rowsResult} scheine={scheine} gopNumbersInput={gopNumbersInput} kv={kv} viewOnlyMode={viewOnlyMode} userStatus={userStatus} abrechnungBillingOption={abrechnungBillingOption} debug={debug} maxHeight={tableMaxHeight} />
                        
                        <br /><br />
                    </div>
            </>}
 
        </>
    );
};

export default EBM_Abrechnungskompass_Results;