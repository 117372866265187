import { apiCall } from '../../apiHelpers/apiCall';

export const getGoaeMissingData = async (page, rowsPerPage, search, per_user_data) => {
   
    try{
        const response = await apiCall(`/api/get_goae_missing?page=${page}&perPage=${rowsPerPage}&s=${search}&per_user_data=${per_user_data}`);
        
        const jsonData = await response.json();

        return jsonData;

    }catch(error){
        console.error(error.message);
    }
}