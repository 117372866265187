import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { dangerouslySetInnerHTML } from 'react-dom';
import { Typography } from '@mui/material';
import { Link } from '@mui/material';
import { Tooltip } from '@mui/material';
import { TextField } from '@mui/material';
import { Box } from '@mui/material';
import { ContentComponent, MembershipStatus, MembershipStatusDanke } from '../../components/ContentForFullMemberShip';
import ViewFormOutputMembership from '../../components/ViewFormOuputMembership';
import DialogOutput from '../../../../components/DialogOutput';
import ViewFormOutput from '../../components/ViewFormOuput';

import * as DatabaseService from  '../../../../services/DatabaseService';

import { checkDeviationPercentStatus } from '../../calculations/ebmDeviationConditions';

function insertAbrechnungskompassCSVData_func(data, input, scheine, state_id, abrechnung_billing_no) {
  return DatabaseService.insertAbrechnungskompassCSVData(data, input, scheine, state_id, abrechnung_billing_no)
}

export default function TableSection(props) {

    const { rowsResult, scheine, gopNumbersInput, kv, viewOnlyMode, userStatus, abrechnungBillingOption, debug, maxHeight=500 } = props;

    let processOutputCSV = "";

    useEffect(() => {

      if (!viewOnlyMode && rowsResult && rowsResult.length > 0) {
        insertAbrechnungskompassCSVData_func(processOutputCSV, gopNumbersInput, scheine, kv, abrechnungBillingOption)
      }

    }, [rowsResult, scheine, gopNumbersInput, kv])

    const TableFilter = (props) => {

        const { rowsResult, only_list_include_chart = false, titleHeader="", tableType = "" } = props;
    
        /*Dialog Box*/
        const [open, setOpen] = React.useState(false);
        const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });

        const [openDialogFeatures, setOpenDialogFeatures] = React.useState(false);
        /*End of Dialog Box*/ 

        let rows = [];

        /*console.log("only_list_include_chart")
        console.log(only_list_include_chart)*/
    
        if (only_list_include_chart) {
          rows = rowsResult.filter(row => row.include_chart !== null) // Exclude rows where row.include_chart is null
                           .sort((a, b) => a.gop - b.gop);

        } else {
          //rows = rows;  // No filter applied
          rows = rowsResult.filter(row => {
            // Add your filter condition here
            // For example, to include all rows, you can return true
            return true;
          }).sort((a, b) => a.gop - b.gop);
        }
    
        let currentHeader = null;
        let backgroundColorRow = '#00000000';
        let countRowForBgColor = 0; //this will help start with a white row
    
        processOutputCSV += '"' + titleHeader + '"\n';
    
        processOutputCSV += "EBM-Ziffer,Leistung,Anzahl,Soll,Abweichung,Abweichung %" + ((tableType === "1" || debug) ? ",Gesamtpotenzial,Ihr Potenzial in €" : "") + "\n"
    

        const handleViewForm = (gop, description) => {
          setDialogInformation({ title: gop, information: description, status: "view" });
          setOpen(true);
        }
    
        const handleViewFormDialogFeatures = () => {
            setDialogInformation({ title: "", information: "", status: "view" });
            setOpenDialogFeatures(true);
        }
    
        const handleClose = () => {
            setOpen(false);
            setOpenDialogFeatures(false);
        };
  

        return (<>
        
          {
            (userStatus !== 3) ?  <Typography variant="h6" gutterBottom>
                                    {titleHeader} 
                                  </Typography> 
                                : ""
          }

         
          
            <TableContainer component={Paper} sx={{borderRadius:0, maxHeight: maxHeight}}>
                    <Table size="small" aria-label="simple table" sx={{tableLayout: 'auto'}}>
                      <TableHead>
                        {
                          (userStatus !== 3) ?
                            <TableRow sx={{
                                            backgroundColor: '#006400', 
                                            '& .MuiTableCell-root': { color: 'white', textAlign: 'right', whiteSpace: 'nowrap'},
                                            position: 'sticky',
                                            top: 0, // Ensures the header sticks to the top
                                            zIndex: 1, // Keeps it above other content when scrolling
                                          }}>
                              <TableCell sx={{textAlign: 'left!important'}}>EBM-Ziffer</TableCell>   
                              <TableCell sx={{textAlign: 'left!important'}}>Leistung</TableCell>
                              <TableCell>Anzahl</TableCell>
                              <TableCell>Soll</TableCell> {/*Ideal number of treatments*/}
                              <TableCell>Abweichung</TableCell>
                              <TableCell>Abweichung %</TableCell>
                              { (debug) ? <TableCell>EBM-Koeffizient</TableCell> : <></> }
                              { 
                                (tableType === "1" || debug) ? 
                                  <>
                                    <TableCell>Gesamtpotenzial in €</TableCell>
                                    <TableCell>Ihr Potenzial in €</TableCell>
                                  </> 
                                  : 
                                  "" 
                              }
                              { (tableType === "2") ? <TableCell>{/* Caution */}</TableCell> : "" }
                            </TableRow>
                          : ""
                        }
                      </TableHead>
                      <TableBody sx={{color: '#134020',  position: 'relative', '& .MuiTableCell-root': { border:0 }}}>
      
                        <MembershipStatus 
                          userStatus={userStatus}
                          onClick={()=>{ handleViewFormDialogFeatures() }}
                        />
      
                        {rows.map((row, index) => {
                            
                            const tableRows = [];
      
                            let outputGop = row.gop;
                            let outputTitle = row.title;
                            let ouputAnzahl = row.anzahl;
                            let outputIdealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);
                            let outputDeviation = ((!isNaN(parseFloat(row.deviation))) ? parseFloat(row.deviation).toFixed(0).toString().replace('.', ',') : 0);
                            let ouputDeviationInPercent = isNaN(parseFloat(row.deviation_in_percent)) ? '0' : parseFloat(row.deviation_in_percent).toFixed(2).toString().replace('.', ',') + '%';
                            
                            /*let outputGezamtpotenzial = (row.exclude_scheine === 1) ? (
                                                                                        (typeof row.euro_potencial_mutterschaftsvorsorge_ziffern === 'undefined') ? 
                                                                                        parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',') : parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern).toFixed(2).toString().replace('.', ',')
                                                                                      ) : (
                                                                                        parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',')
                                                                                      );*/

                              /*let outputGezamtpotenzial;
                              if (row.exclude_scheine === 1) {
                                if (typeof row.euro_potencial_mutterschaftsvorsorge_ziffern === 0) {
                                  outputGezamtpotenzial = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                                } else {
                                  outputGezamtpotenzial = parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern).toFixed(2).toString().replace('.', ',');
                                }
                              } else {
                                outputGezamtpotenzial = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                              }*/

                                let outputGezamtpotenzial;
                                
                                if (row.exclude_scheine === 1) {
                                  if (row.euro_potencial_mutterschaftsvorsorge_ziffern === 0) {
                                    //outputGezamtpotenzial = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                                    outputGezamtpotenzial = parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',');
                                  } else {
                                    outputGezamtpotenzial = parseFloat(row.euro_potencial_mutterschaftsvorsorge_ziffern).toFixed(2).toString().replace('.', ',');
                                  }
                                } else {
                                  outputGezamtpotenzial = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                                }

                            /*let outputIhrPotenzialInEuro =  (typeof row.euro_potencial_show === 'undefined') ? 
                                                                  parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',') : 
                                                                    (row.euro_potencial_show) ? 
                                                                        (typeof row.adjusted_euro_potencial === 'undefined') ? 
                                                                            parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',') : parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',') 
                                                                    : '-';*/

                            let outputIhrPotenzialInEuro;
                            
                            //outputIhrPotenzialInEuro = parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',');

                            if(row.euro_potencial_show){
                                  outputIhrPotenzialInEuro = parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',');
                            }else{
                                  outputIhrPotenzialInEuro = '-';
                            }
                            

                                /*let outputIhrPotenzialInEuro;

                                if (typeof row.euro_potencial_show === 'undefined') {
                                    outputIhrPotenzialInEuro = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                                } else {
                                    if (row.euro_potencial_show) {
                                        if (typeof row.adjusted_euro_potencial === 'undefined') {
                                            outputIhrPotenzialInEuro = parseFloat(row.euro_potencial).toFixed(2).toString().replace('.', ',');
                                        } else {
                                            outputIhrPotenzialInEuro = parseFloat(row.adjusted_euro_potencial).toFixed(2).toString().replace('.', ',');
                                        }
                                    } else {
                                        outputIhrPotenzialInEuro = '-';
                                    }
                                }*/
                            
                            let outputDeviationInPercentStatus = checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).info; 
      
                              if(debug){
                                  //console.log("row.length")
                                  //console.log(row.length)
                              }
                              
                              if (row.header_description !== currentHeader) {
                                
                                currentHeader = row.header_description;
                                
                                countRowForBgColor = 0;
      
                                processOutputCSV += '"' + currentHeader + '"\n';
      
                                
                                  tableRows.push(
                                    <TableRow key={index} sx={{backgroundColor: '#CAE3B6', '& .MuiTableCell-root': { color: '#134020'}}}>
                                      <TableCell colSpan={10}>
                                        {/*<div dangerouslySetInnerHTML={{ __html: currentHeader }} />*/}
                                        <ContentComponent 
                                          userStatus={userStatus} 
                                          content={
                                            <div dangerouslySetInnerHTML={{ __html: currentHeader }} />
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ) 
                              }
      
                              if((checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).backgroundColor !== '#00000000')){
                                backgroundColorRow = checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).backgroundColor;
                              }else{
                                backgroundColorRow = (countRowForBgColor % 2 === 0 ? 'white' : '#F1F2F4')
                              }
      
                                //if(userStatus){
                                  tableRows.push(
                                    <TableRow
                                        key={row.id}
                                        sx={{ 
                                              '&:last-child td, &:last-child th': { border: 0 }, 
                                              backgroundColor: backgroundColorRow,
                                              '& .MuiTableCell-root': {color: checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color, textAlign: 'right'},
                                              '& .MuiTableCell-root a': {color: checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color_href, textDecorationColor: checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color_href }
                                          }} 
                                      >
                                        <TableCell sx={{textAlign: 'left!important'}}>
                                          
      
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={
                                              
                                              (row.description !== null) 
                                                ? <>
                                                    <Link 
                                                        sx={{cursor:'pointer'}} 
                                                        onClick={()=>{ handleViewForm(row.gop, row.description) }}
                                                    >{outputGop}
                                                    </Link>
                                                  </> : <>{outputGop}</>
      
                                            }
                                          />
      
                                        </TableCell> {/*EBM-Ziffer*/}
                                        {/*<TableCell sx={{textAlign: 'left!important'}}><Tooltip title={outputTitle} placement="top"><StyledTextArea defaultValue={row.title} disabled></StyledTextArea></Tooltip></TableCell>*} {/*Leistung*/}
                                        <TableCell sx={{textAlign: 'left!important'}}>
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={
                                              
                                                <Tooltip title={outputTitle} placement="top">
                                                <TextField 
                                                  defaultValue={outputTitle} 
                                                  size="small" 
                                                  fullWidth  
                                                  InputProps={{
                                                    readOnly: true,
                                                    sx: {
                                                          fontSize: '14px',
                                                          '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent', // Removes the border color
                                                          },
                                                          '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent', // Removes border color on hover
                                                          },
                                                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent', // Removes border color on focus
                                                          },
                                                        }
                                                  }}
                                                  inputProps={{
                                                    style: {
                                                      padding: '3px',   // Inner padding of the input element
                                                      overflow: 'hidden',         // Hide overflowing content
                                                      whiteSpace: 'nowrap',       // Prevent text from wrapping
                                                      textOverflow: 'ellipsis',   // Show ellipsis (...) when text overflows
                                                      color: (checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).color)
                                                    },
                                                  }}
                                                />
                                                </Tooltip>
      
                                            }
                                          />
                                        </TableCell> {/*Leistung*/}
                                        <TableCell> 
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={ouputAnzahl} 
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={ outputIdealNumberOfTreatments } 
                                          />
                                        </TableCell> {/*Ideale Anzahl an Behandlungen (Soll)*/}
                                        {/*<TableCell>{ parseFloat(row.deviation).toFixed(2).toString().replace('.', ',') }</TableCell>*/} {/*Abweichung*/}  
                                        <TableCell>
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={ outputDeviation } 
                                          />
                                          </TableCell> {/*Abweichung*/}
                                        <TableCell> 
                                          <ContentComponent 
                                            userStatus={userStatus} 
                                            content={ ouputDeviationInPercent }
                                          />
                                        </TableCell>{/*Abweichung %*/}    
                                        { (debug) ? <TableCell>{ row.ebm_koeffizient.toString().replace('.', ',') }</TableCell>: <></> } {/*EBM-Koeffizient*/}                                            
                                        
                                        { 
                                          (tableType === "1" || debug) ? 
                                          <>
                                            <TableCell>
                                              <ContentComponent 
                                                userStatus={userStatus} 
                                                content={ outputGezamtpotenzial } 
                                              />
                                            </TableCell> {/*Gesamtpotenzial*/}
                                            <TableCell> 
                                              <ContentComponent 
                                                userStatus={userStatus} 
                                                content={ outputIhrPotenzialInEuro } 
                                              />
                                            </TableCell> {/*Ihr Potenzial in €*/}
                                          </>
                                          : 
                                          <></>
                                        }
      
                                        {
                                          (tableType === "2") ? 
                                            <TableCell sx={{textAlign: 'left!important'}}>
                                              <ContentComponent 
                                                userStatus={userStatus} 
                                                content={
                                                  <Box sx={{display: 'flex', alignItems: 'center', fontWeight: 'bold'}}><Box>{checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).icon}</Box><Box sx={{ml: 1}}>{checkDeviationPercentStatus(row.deviation_in_percent_status, tableType).info}</Box></Box>
                                                } 
                                              />
                                            </TableCell> : ""
                                        } 
                                      </TableRow>
      
                                  )
                                //}
      
                                processOutputCSV +=  '"' + outputGop + '",' +
                                                      '"' + outputTitle + '",' +
                                                      '"' + ouputAnzahl  + '",' +
                                                      '"' + outputIdealNumberOfTreatments + '",' +
                                                      '"' + outputDeviation + '",' +
                                                      '"' + ouputDeviationInPercent + '"' +
                                                      ((tableType === '1' || debug) ? ',"' + outputGezamtpotenzial + '","' + outputIhrPotenzialInEuro + '"' : '') +
                                                      ((tableType === '2') ? ',"' + outputDeviationInPercentStatus + '"' : '') + '\n';
    
                              countRowForBgColor++;
                              
                              return (userStatus !== 3) ? tableRows : "";
                          }
                        )}
                        
                      </TableBody>
                    </Table>
              </TableContainer>
          
                <DialogOutput
                      fullScreen={false} // Set the value according to your requirement
                      open={open}
                      dialogData={dialogData}
                      handleClose={handleClose}
                      output={<ViewFormOutput description={dialogData.information} />}
                />

                <DialogOutput
                      fullScreen={false} // Set the value according to your requirement
                      open={openDialogFeatures}
                      dialogData={dialogData}
                      handleClose={handleClose}
                      maxWidth='lg'
                      output={<ViewFormOutputMembership />}
                />
        </>);
    
      }
    
    
    return (
        <>
            <MembershipStatusDanke userStatus={userStatus} />

            <TableFilter rowsResult={rowsResult} only_list_include_chart={true} titleHeader="EBM-Ziffern der freien Leistungen" tableType="1" debug={false} />

            <br />
            
            <TableFilter rowsResult={rowsResult} titleHeader="Gesamttabelle" tableType="2" debug={false} />

        </>
    );
}