import React from 'react';
import { Grid, Paper } from '@mui/material';

import PieChartFilter from '../../chart_filters/PieChartFilter';
import SpeedometerFilter from '../../chart_filters/SpeedometerFilter';
import BarChartFilter from '../../chart_filters/BarChartFilter';
import PieChartFilter2 from '../../chart_filters/PieChartFilter2';
import ScheineChartFilter from '../../chart_filters/ScheineChartFilter';
import StatusChartFilter from '../../chart_filters/StatusChartFilter';

const Charts_Section = (props) => {

    const { rowsResult, 
            gesamtpotenzial_included, 
            ihrPotencialIncluded, 
            gesamtpotenzial_included_excludedGOP,
            ihrPotencialIncluded_excludedGOP,
            gesamtpotenzial_notrap, 
            ihrpotenzial_notrap, 
            scheine,
            offeneSprechstunde
         } = props;

    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <PieChartFilter gesamtpotenzial_included_excludedGOP={gesamtpotenzial_included_excludedGOP} ihrPotencialIncluded_excludedGOP={ihrPotencialIncluded_excludedGOP} />
            </Grid>
            <Grid item xs={4}>
                <SpeedometerFilter gesamtpotenzial_included={gesamtpotenzial_included} ihrPotencialIncluded={ihrPotencialIncluded} />
            </Grid>
            <Grid item xs={4}>
                <BarChartFilter rowsResult={rowsResult} />
            </Grid>
        </Grid>

        <br />

        <Grid container spacing={2}>
            <Grid item xs={4}>
                <PieChartFilter2 gesamtpotenzial_notrap={gesamtpotenzial_notrap} ihrpotenzial_notrap={ihrpotenzial_notrap} />
            </Grid>
            <Grid item xs={4}>
                <ScheineChartFilter scheine={scheine} offeneSprechstunde={offeneSprechstunde} />
            </Grid>
            <Grid item xs={4}>
                <StatusChartFilter rowsResult={rowsResult} />
            </Grid>
        </Grid>
        </>
    );
};

export default Charts_Section;